import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Theme {
    customColor: {
      main: string;
    };
  }
  // allow configuration using `createTheme`
  interface PaletteOptions {
    customColor?: PaletteOptions["primary"];
  }
}
const theme = createTheme({
  palette: {
    primary: {
      // main: "#FE0505",
      main: "#00BFFF",
    },
    secondary: {
      main: "#0EC2CD",
      // main: "#FFD700",
    },
    customColor: {
      // main: "#263238",
      main: "black",
    },
    text: {
      primary: "#000000", // Black text for high contrast
      secondary: "#1E201E",
    },
    background: {
      //default: "#FFFFFF", // White background for high contrast
      // paper: "#F5F5F5", // Light grey background for high contrast
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
  breakpoints: {
    values: {
      xs: 0, // Extra small devices (phones)
      sm: 600, // Small devices (tablets)
      md: 960, // Medium devices (desktops)
      lg: 1280, // Large devices (desktops)
      xl: 1920, // Extra large devices (large desktops)
    },
  },
});

export default theme;
