import { Container, Box, Typography } from "@mui/material";
import logo from "../../shared/images/LogoColor.png";
import headerImg from "../../shared/images/bg2.webp";
import "./Hero.component.scss";
export const Hero = () => {
  return (
    <Container maxWidth="xl" id="home" sx={{ padding: 0 }}>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: { xs: "400px", sm: "500px", md: "600px" }, // Responsive height
          overflow: "hidden",
        }}
      >
        {/* Background Image */}
        <img
          src={headerImg}
          alt="Header"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />

        {/* Overlay content */}
        <Box
          className="overlay"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Dark transparent overlay
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            color: "white",
            textAlign: "center",
            padding: { xs: "1rem", md: "2rem" },
          }}
        >
          {/* Main Title */}
          <Typography variant="h3" component="h1" sx={{ fontSize: { xs: "1.5rem", sm: "2rem", md: "3rem" } }}>
            Intra in posesia unui voucher
            <br /> si hai sa zburam!
          </Typography>

          {/* Subtitle */}
          <Typography variant="subtitle1" sx={{ fontSize: { xs: "1rem", md: "1.25rem" }, marginTop: "1rem" }}>
            Distractie si adrenalina
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};
