import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { IProducts } from '../../../models/IProducts';

interface Customer{
  firstName: string;
  lastName: string;
  address: string;
  email: string;
  phone: string;

}

interface Parameters{
    orderId: string;
    amount: number;
    currency: string;
    customerInfo: Customer;
    shoppingCartItems: IProducts[];
}

interface PaymentState{
    paymentRequest: string | null;
    loading: "idle" | "pending" | "succeeded" | "failed";
    error: string | undefined;
}

const initialState: PaymentState={ 
    paymentRequest: null,
    loading: 'idle',
    error: ''
}
export const initiatePayment = createAsyncThunk<
  any,
  Parameters 
>(
  'payment/initiatePayment',
  async ({ orderId, amount, currency, customerInfo, shoppingCartItems } , thunkAPI) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/payment/initiate`, { orderId, amount, currency, customerInfo, shoppingCartItems });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(initiatePayment.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(initiatePayment.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.paymentRequest = action.payload;
      })
      .addCase(initiatePayment.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.payload as string;
      });      
  },
});


export default paymentSlice.reducer;