import { Box, Grid, Typography, Link, ThemeProvider } from "@mui/material";
import { Facebook, Instagram, YouTube, LinkedIn } from "@mui/icons-material";
import logo from "../../shared/images/LogoColor.png";
import netopia from "../../shared/images/payment/netopia.svg";
import mastercard from "../../shared/images/payment/mc_symbol.svg";
import visa from "../../shared/images/payment/visa-svgrepo-com.svg";
import "./Footer.component.scss";
import { GeneralTerms } from "../termeni-conditii/GeneralTerms.component";
import { useState } from "react";
import { FlyTerms } from "../termeni-conditii/FlyTerms.component";
import theme from "../../shared/theme/themes";

export const Footer = () => {
  const [open, setOpen] = useState(false);
  const [openTCZB, setOpenTCZB] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenTCZB = () => setOpenTCZB(true);
  const handleCloseTCZBlose = () => setOpenTCZB(false);
  return (
    <ThemeProvider theme={theme}>
      <Box className="footer">
        <Grid container spacing={4} className="footer-container">
          <Grid item xs={12} md={4} className="footer-logo-section">
            <img src={logo} alt="KronPara Logo" className="footer-logo" />
            <Typography variant="body1">Distracție Adrenalină Siguranță</Typography>
            <Box className="social-media">
              <Link href="#" color="inherit">
                <Facebook />
              </Link>
              <Link href="#" color="inherit">
                <Instagram />
              </Link>
              <Link href="#" color="inherit">
                <YouTube />
              </Link>
              <Link href="#" color="inherit">
                <LinkedIn />
              </Link>
            </Box>
            <Typography variant="body2" className="copyright">
              © Copyright 2024 kronpara.ro
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} className="footer-partners-section">
            <Typography variant="h6">Metode de plată</Typography>
            <Box className="partners-logos">
              <img src={netopia} alt="Netopia" />
              <img src={mastercard} alt="Mastercard" />
              <img src={visa} alt="Visa" />
            </Box>
            <Box sx={{ mt: 4, display: "flex", gap: 2 }}>
              <Typography
                variant="body2"
                sx={{
                  "&:hover": {
                    color: "customColor.main",
                    cursor: "pointer",
                  },
                }}
                className="terms"
                onClick={handleOpen}
              >
                Termeni și condiții generale
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  "&:hover": {
                    color: "customColor.main",
                    cursor: "pointer",
                  },
                }}
                className="terms"
                onClick={handleOpenTCZB}
              >
                Termeni și condiții de zbor cu parapanta
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} className="footer-menu-section">
            <Box className="footer-menu-container">
              <Typography variant="h6">Meniu</Typography>
              <ul className="footer-menu">
                <li>
                  <Link href="#" color="inherit">
                    Acasă
                  </Link>
                </li>
                <li>
                  <Link href="#" color="inherit">
                    Despre zbor
                  </Link>
                </li>
                <li>
                  <Link href="#" color="inherit">
                    Galerie Media
                  </Link>
                </li>
                <li>
                  <Link href="#" color="inherit">
                    Vouchere
                  </Link>
                </li>
                <li>
                  <Link href="#" color="inherit">
                    Contact
                  </Link>
                </li>
              </ul>
            </Box>
          </Grid>
        </Grid>
        <GeneralTerms open={open} handleClose={handleClose} />
        <FlyTerms open={openTCZB} handleClose={handleCloseTCZBlose} />
      </Box>
    </ThemeProvider>
  );
};
