import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IProducts } from "../../models/IProducts";
const isDevelopment = process.env.NODE_ENV === "development";
console.log("isDevelopment", isDevelopment);

export const getProducts = createAsyncThunk<IProducts[]>("products/getProducts", async () => {
  const products = await axios.get<IProducts[]>(
    isDevelopment
      ? `${process.env.REACT_APP_BACKEND_DEVELOPMENT_URL}/products/`
      : `${process.env.REACT_APP_BACKEND_URL}/products/`
  );

  return products.data;
});
