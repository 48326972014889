import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import "./InfoClient.component.scss";
import { Button, useMediaQuery } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useState } from "react";
import { InfoClientDialog } from "./InfoClientDialog.component";
import theme from "../../shared/theme/themes";

export const InfoClient = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className={isMobile ? "how-it-works-mobile" : "how-it-works"}>
      <h2>Cum funcționează?</h2>
      <div className="steps">
        <div className="step">
          <CheckCircleOutlineIcon className="icon" />
          <p>Alegi una din experiențele noastre</p>
        </div>
        <div className="separator"></div>
        <div className="step">
          <CalendarTodayIcon className="icon" />
          <p>Achiziționezi un voucher</p>
        </div>
        <div className="separator"></div>
        <div className="step">
          <EmailIcon className="icon" />
          <p>Primești voucher-ul prin email</p>
        </div>
        <div className="separator"></div>
        <div className="step">
          <PhoneIcon className="icon phone" />
          <p className="telefonic">
            Stabilim împreună data și ora zborului
          </p>
        </div>
      </div>
      <div className="more-info-container">
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<InfoIcon />}
          onClick={handleClickOpen}
          fullWidth={isMobile}
          className={isMobile ? "more-info-button-mobile" : "more-info-button"}
        >
          Mai multe informații
        </Button>
      </div>
      <InfoClientDialog open={open} handleClose={handleClose} />
    </div>
  );
};
