import {
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  IconButton,
  ListItemText,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from 'react-router-dom';

interface ShoppingCartProp {
  cartItems: any[];
  onRemoveItem: (id: string) => void;
  onCloseDrawer: () => void;
}

export const ShoppingCart = ({ cartItems, onRemoveItem, onCloseDrawer }: ShoppingCartProp) => {
  const navigate = useNavigate();

  const totalAmount = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);

  const handleFinalizeOrder = () => {
    navigate('/complete-order');
  };
  return (
    <Box role="presentation" sx={{ width: 350 }}>
      <Typography variant="h6" sx={{ padding: 2 }}>
        Coș de cumpărături
      </Typography>
      <Divider />
      {cartItems.length === 0 ? (
        <Typography variant="body1" sx={{ padding: 2 }}>
          Coșul este gol
        </Typography>
      ) : (
        <List>
          {cartItems.map((item, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => onRemoveItem(item._id)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText
                primary={`${item.name} x ${item.quantity}`}
                secondary={`${item.price} LEI`}
              />
            </ListItem>
          ))}
        </List>
      )}
      <Divider />
      {cartItems.length > 0 && (
        <Box sx={{ padding: 2 }}>
          <Typography variant="h6">Total: {totalAmount} LEI</Typography>
        </Box>
      )}
      <Box sx={{ padding: 2 }}>
        <Button variant="contained" color="primary" disabled={cartItems.length === 0} onClick={handleFinalizeOrder} fullWidth>
          Finalizează comanda
        </Button>
      </Box>
      <Box sx={{ padding: 2 }}>
        <Button variant="outlined" color="secondary" fullWidth onClick={onCloseDrawer}>
          Continua cumpărăturile
        </Button>
      </Box>
    </Box>
  );
};
