import { Modal, Box, Typography, IconButton, useTheme } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800, // Set the width of the modal
  bgcolor: "background.paper",
  border: "2px solid ##EEEEEE",
  boxShadow: 24,
  p: 8,
  maxHeight: "80vh",
  overflowY: "auto",
  borderRadius: "16px",
};

interface GeneralTermsProps {
  open: boolean;
  handleClose: () => void;
}
export const FlyTerms: React.FC<GeneralTermsProps> = ({ open, handleClose }) => {
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="fly-terms-title"
        aria-describedby="fly-terms-description"
      >
        <Box sx={style}>
          {/* Close Button */}
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="fly-terms-title" variant="h6" component="h2" gutterBottom>
            Termeni si condiții pentru zborul cu parapanta sau navigația cu vele.
          </Typography>
          <Typography id="fly-terms-description" variant="body1" component="div">
            <p>
              Introducere – Prezentul document reprezintă termenii si condițiile prin care echipa Kronstadt Paragliding
              desfășoară zboruri în tandem cu parapanta, desfășoară cursuri de zbor cu parapanta sau organizează ture de
              navigație cu velierul. Accepul acestora reprezină o condiție minimă obligatorie pentru pasagerii unui zbor
              cu parapanta în tandem sau care participă la un curs de instruire în vederea obținerii licenței de pilot
              parapantă. Vă rugăm citiți cu atenție acești termeni si conditii.
            </p>
            <p>
              Echipa Kronstadt Paragliding este formată din piloți profesioniști, certificați pentru zborul cu pasageri
              sau piloți instructori de parapantă cu sute de ore de zbor și mulți ani de experiență. Toate echipamentele
              cu care veți zbura sunt certificate și omologate pentru zborul cu pasageri având reviziile la zi.
            </p>

            <Typography variant="h6" component="h3" gutterBottom>
              Cap.1 Condițiile minime de eligibilitate pentru pasageri
            </Typography>
            <p>1.1. Greutate. Pasagerul trebuie sa aibă greutatea cuprinsă între 35-100 kg</p>
            <p>
              1.2. Vârstă. Nu există limită de vârsta nici superioară nici inferioară, dar copiii vor zbura numai cu
              acordul scris al părințiilor. Al ambilor.
            </p>
            <p>
              1.3. Condiție fizică. Nu este nevoie de o pregătire fizica prealabilă, dar pasagerii trebuie sa fie
              pregătiți sa facă câțiva pași în alergare pentru decolare și încă câțiva pași pentru aterizare.
            </p>
            <p>
              1.4. Echipamentul minim constă în: bocanci sau pantofi sport cu talpa aderentă, pantaloni lungi si bluză
              cu mâneca lungă (chiar și vara). Iarna, trebuie să vă îmbrăcați adecvat anotimpului, ideal fiind un costum
              de ski. Restul echipamentului de zbor vă va fi furnizat de noi.
            </p>
            <p>
              1.5. Decolarea parapantei. Pentru decolare pasagerii trebuie să poată alerga câțiva pași așa cum va fi
              instruit de către pilot. La parapantă nu sărim, nu ne aruncăm și nu cădem în gol. Decolarea se face de
              obicei de pe o pantă înierbată vara si acoperita de zăpada iarna.
            </p>
            <p>
              1.6. Aterizarea parapantei. Pentru aterizare, de asemenea pasagerii trebuie să poată alerga câțiva pași,
              în picioare atunci cîn va fi solicitat de către pilot.
            </p>
            <p>
              1.7. Filmarea zborului și poze. În funcție de tipul de zbor ales, pasagerii beneficiaza de filmarea
              zborului și poze, de obicei incluse in prețul zborului. Pasagerii le vor primi pe email in termen de max
              48 ore, folosind un site de transfer online. Le vom păstra apoi în arhiva noastră pentru încă 30 de zile,
              timp în care se pot retrimite. După expirarea acestui termen ele se vor șterge definitiv.
            </p>

            <Typography variant="h6" component="h3" gutterBottom>
              Cap.2 Rezervarea unui zbor – programarea și anularea zborului cu parapanta
            </Typography>
            <p>
              2.1. Rezervarea inițială: Un zbor cu parapanta se poate rezerva oricând telefonic, prin email sau direct
              de pe unul din site-urile noastre, completând un formular de rezervare. În funcție de dorințele
              pasagerilor și disponibilitatea piloților noștrii vă vom programa la cea mai convenabilă oră, de comun
              acord.
            </p>

            <p>
              2.2. Confirmarea zborului: Zborul cu parapanta este strict dependent de condițiile meteo, în special de
              direcția și intensitatea vântului. Ca urmare, orice rezervare făcută la noi va trebui re-confirmată cu
              maxim 48 ore înaintea efectuării zborului. Vă rugăm să ne contactați, în cazul în care nu am facut-o noi
              între timp cu o zi înaintea datei programate.
            </p>

            <p>
              2.3. Re-planificarea sau anularea zborului: Siguranța dumneavoastră este prioritară pentru noi. Deși facem
              eforturi pentru a minimiza cazurile în care clienții vin la zbor și condițiile nu sunt prielnice se poate
              întâmpla ca vremea să se schimbe brusc. În acest caz piloții pot lua decizia să amâne sau să anuleze
              zborul. Este o chestiune de siguranță pe care fiecare pilot o va decide pe baza experienței sale, decizie
              pe care nu dorim și nu putem să o influențăm. Ca atare ne rezervăm dreptul de a reprograma sau anula
              zborul în orice moment, dacă condițiile meteo nu sunt propice. Echipa Kronstadt Paragliding nu poate
              rambursa eventualele costuri cu transportul sau cazarea în cazul anulării sau amânării zborului din cauza
              condițiilor meteo.
            </p>

            <Typography variant="h6" component="h3" gutterBottom>
              Cap.3 Voucher cadou – condiții de folosire și returnare
            </Typography>
            <p>
              3.1. Definiții. Un voucher cadou reprezintă practic un zbor pre-plătit pentru care vă vom emite un
              document electronic cu un cod unic (voucher-ul), împreună cu factura fiscală aferentă.
            </p>

            <p>
              3.2. Valabiltate și eligibilitate. Voucherul cadou este valabil 360 de zile din momentul emiterii și se
              poate folosi o singură dată pentru un zbor cu parapanta în tandem, pe baza prezentării fizice a acestuia
              sau a codului unic care va fi validat. Voucherul nu este nominal și se poate tranfera oricărui pasager
              care îndeplinește condițiile de la cap. 1
            </p>

            <p>
              3.3. Achiziție si plata. Voucherul se poate achiziționa online prin selectarea voucher-ului dorit si
              completarea formularului de contact folosind urmatorul link: https://kronpara.ro
            </p>

            <p>
              3.3.1. Plata prin card – Apăsând butonul “plata card” de la finalul formularului de comanda veți fi
              redirecționat către o pagina securizată de plata prin intermediul partenerilor noștri autorizați.
            </p>

            <p>
              3.4. Politica de retur/anulare a contravalorii voucher-ului. După expirarea valabilității voucherului,
              acesta nu se mai poate folosi, returna sau rambursa.
            </p>

            <p>
              Voucherul poate fi restituit si contravaloarea acestuia rambursată integral în termen de 14 zile de la
              achiziție, în urma unei solicitări scrise, fără a prezenta nici un motiv pentru acest lucru.
            </p>

            <p>
              În termenul de valabilitate al voucher-ului acesta se poate returna, iar clientul va primi în cont
              contravaloarea integrală a acestuia numai în condițiile următoare: au fost făcute 2 programări succesive,
              clientul final s-a prezentat de fiecare dată la locația și ora stabilite, dar de fiecare dată zborul a
              fost anulat din cauza condițiilor meteo nefavorabile sau a indisponibilității pilotului.
            </p>

            <p>
              Deși nu se poate anula din alte motive, voucherul se poate transfera și poate fi folosit de către o terță
              persoană în termenul de valabilitate al acestuia.
            </p>

            <Typography variant="h6" component="h3" gutterBottom>
              Cap.4 Siguranța zborului – obligații și răspunderi legale are prestatorului si ale clienților
            </Typography>
            <p>
              Când vorbim de siguranța zborului avem în vedere trei aspecte: echipamentul folosit de noi, experiența
              piloților și alegerea corectă a condițiilor de zbor.
            </p>

            <p>
              4.1. Echipamentul nostru. Zburăm numai cu echipament certificat și omologat, înțelegând prin aceasta
              parapantă, seletă cu protecție la impact, cască și pentru un plus de siguranță și o parașută de rezervă
              adecvată zborului în tandem. Pasagerii pot solicita oricând inspectarea echipamentelor de siguranță
              (parașută de rezervă, cască, protectorul seletei, etc.)
            </p>

            <p>
              4.2. Piloții noștri. Toți cei cu care veți zbura în tandem sunt licențiați în zborul cu pasageri sau au
              calificarea de instructori de parapantă sau piloți tandem parapantă, au minim 8 ani de experiență în
              zborul cu parapanta și un minim de 1000 zboruri. Pasagerii au dreptul de a solicita oricând licența
              piloților cu care zboară.
            </p>

            <p>
              4.3. Alegerea condiției de zbor. Nu vom efectua în zborul cu pasageri manevre periculoase sau în afara
              anvelopei de zbor a aeronavei și ne vom asigura în permanență că nu vom zbura deasupra zonelor periculoase
              sau interzise. Dacă condițiile de zbor nu sunt optime ne rezervăm dreptul de a amâna sau chiar anula
              decolarea în orice moment, la libera discreție a pilotului.
            </p>

            <p>
              4.4. Limitări de responsabilitate. Cu toate precauțiile enumerate mai sus, ca în orice sport, pot apărea
              incidente și foarte rar accidente. Decizia de a participa la un zbor cu parapanta în tandem aparține în
              ultimă instanță pasagerilor (sau a reprezentanților legali în cazul minorilor) iar aceștia trebuie să își
              asume implicit și riscurile asociate practicării acestui sport așa cum sunt menționate în acest document.
              In aceste condiții, compania noastră ca persoană juridică și implicit site-urile https://kronpara.ro,
              precum și piloții cu care noi colaborăm ca persoane fizice nu pot fi făcuți responsabili pentru
              eventualele accidentări ale pasagerilor iar acesta din urmă nu va solicita din partea noastră despăgubiri
              în caz de accident.
            </p>

            <Typography variant="h6" component="h3" gutterBottom>
              Cap.5 Termeni si condiții de plată
            </Typography>
            <p>
              5.1. Plata prin card. În momentul rezervării online sau după efectuarea zborului pasagerii pot achita prin
              card contravaloarea zborului direct din site, accesând link-ul de plată cu cardul din pagina de rezervări
              (https://kronpara.ro).
            </p>

            <Typography variant="h6" component="h3" gutterBottom>
              Cap.6. Politica de confidențialitate, colectarea și siguranța datelor
            </Typography>
            <p>
              6.1. Datele pe care le colectăm. Conform regulametului EU 679/2016 și cu acceptul dumneavoastră societatea
              noastră va colecta și prelucra următoarele date personale: nume, telefon, adresa de email si adresa
              poștală în următoarele scopuri:
            </p>

            <ul>
              <li>emiterea unui voucher cadou și a facturii aferente </li>
              <li>rezervarea unui zbor cu parapanta – pentru a vă</li>
              <li>răspunde în scris eventualelor întrebaări</li>
            </ul>

            <p>
              6.2. Stocarea datelor. Datele astfel colectate vor fi stocate în baza noastră de date pentru o perioadă de
              360 zile echivalentă cu valabilitatea voucherului achiziționat. În această perioadă datele dumneavoastră
              nu vor fi folosite în alte scopuri și nu vor fi transferate unei terte entități. Ne puteți contacta pe
              adresa: kronpara@gmail.com pentru a verifica starea datelor dumneavostră și a solicita ștergerea sau
              modificarea acestora.
            </p>

            <p>
              La cererea dumneavoastră expresă (prin bifă în formular) vom pastra datele pentru a vă trimite informații
              despre celelalte servicii oferite de societatea noastra, promoții și oferte. Vă puteți retrage oricând
              acceptul prin dezabonare directă sau un email la adresa kronpara@gmail.com
            </p>

            <Typography variant="h6" component="h3" gutterBottom>
              6.3. Siguranța datelor.
            </Typography>

            <p>Comunicarea informațiilor este protejată prin protocolul HTTPS</p>

            <p>Datele sunt stocate pe servere securizate, aflate în locații sigure;</p>

            <p>Periodic se fac copii de siguranță.</p>

            <p>
              Ștergem informațiile cu caracter personal după 360 zile de la ultima interacțiune, dacă nu ați solicitat
              păstrarea informațiilor pentru a primi informații despre celelalte servicii oferite, promoții sau oferte.
            </p>

            <p>
              6.2. Plăți online și confidențialitatea datelor. Pentru plățile online veți fi direcționat către o pagină
              securizată aparținând procesatorului nostru de servicii https://netopia-payments.com/ Confirmăm de
              asemenea, că suntem de acord și ne angajăm ca toate informațiile care vor fi furnizate de Procesator sau
              care sunt obținute de la Utilizatorii Finali in desfășurarea tranzacțiilor să fie confidențiale si
              păstrate în conformitate cu standardele de securitate ale sistemelor de carduri si cu alte standarde de
              Securitate, că nu vom folosi aceste informații în alte scopuri și că nu le vom transmite sau dezvălui unor
              terțe părți, altele decât autoritățile oficiale autorizate prin lege, și de asemenea că nu vom copia sau
              reproduce aceste informații. Societatea noastră garantează de asemenea că persoanele din interiorul
              societății care vor avea acces la aceste date sunt obligate să respecte confidențialitatea informațiilor
              în timpul și după expirarea termenului de valabilitate a contractului individual de muncă sau de
              colaborare sau a mandatului de administrator.
            </p>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};
