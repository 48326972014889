// redux/slices/orderSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

interface OrderState {
  order: any | null;
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: string | undefined;
}

const initialState: OrderState = {
  order: null,
  loading: 'idle',
  error: undefined,
};

export const fetchOrder = createAsyncThunk(
  'order/fetchOrder',
  async (orderId: string, thunkAPI) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/app-orders/${orderId}`);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrder.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchOrder.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.order = action.payload;
      })
      .addCase(fetchOrder.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.payload as string;
      });
  },
});

export default orderSlice.reducer;