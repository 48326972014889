import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IProducts } from "../../../models/IProducts";


interface ShoppingCartState {
  items: IProducts[];
  totalQuantity: 0,
  totalPrice: number;
  lastAddedProduct: IProducts | null
}

const initialState: ShoppingCartState = {
  items: [],
  totalQuantity: 0,
  totalPrice: 0.00,
  lastAddedProduct: null
};

export const shoppingCartSlice = createSlice({
  name: 'shoppingCart',
  initialState,
  reducers: {
    addToCart: (state, action: PayloadAction<IProducts>) => {
      const existingItem = state.items.find(item => item._id === action.payload._id);
      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        state.items.push({ ...action.payload, quantity: 1 });
      }
      state.totalQuantity += 1;
      state.totalPrice += action.payload.price;
      state.lastAddedProduct = action.payload;
    },
    removeFromCart: (state, action: PayloadAction<string>) => {
      const existingItem = state.items.find(item => item._id === action.payload);
      if (existingItem) {
        state.totalQuantity -= existingItem.quantity;
        state.totalPrice -= existingItem.price * existingItem.quantity;
        state.items = state.items.filter(item => item._id !== action.payload);
      }
    },
    clearCart: (state) => {
      state.items = [];
      state.totalQuantity = 0;
      state.totalPrice = 0;
    },
  },
});

export const { addToCart, removeFromCart, clearCart } = shoppingCartSlice.actions;

export default shoppingCartSlice.reducer;