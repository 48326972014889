import {
  Typography,
  Grid,
  Paper,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Container,
  Alert,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import "./Contact.component.scss";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { IMessages } from "../../models/IMessages";
import { newMessage } from "../../store/actions/sendMessage.actions";
import { AppDispatch } from "../../store/store";

export const Contact = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [isNotRobotChecked, setIsNotRobotChecked] = useState(false);
  const [formData, setFormData] = useState<IMessages>({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });
  const [alert, setAlert] = useState<{ severity: "success" | "error"; message: string } | null>(null);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsNotRobotChecked(e.target.checked);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const resultAction = await dispatch(newMessage(formData));
    if (newMessage.fulfilled.match(resultAction)) {
      setAlert({ severity: "success", message: "Mesajul a fost trimis!" });
    } else {
      setAlert({ severity: "error", message: "Erroare trimitere mesaj!." });
    }
  };

  return (
    <Container>
      {alert && <Alert severity={alert.severity}>{alert.message}</Alert>}
      <div className="contact-section" id="contact">
        <Typography variant="h4" className="section-title">
          Contact
        </Typography>
        <Grid container spacing={4} className="contact-container">
          <Grid item xs={12} md={6} className="contact-grid-item">
            <div className="contact-info">
              <Typography variant="h6">Informații și rezervări</Typography>
              <div className="info-item">
                <PhoneIcon />
                <Typography>+40735628164 Ovidiu</Typography>
              </div>
              <div className="info-item">
                <PhoneIcon />
                <Typography>+40741569472 Sabina</Typography>
              </div>
              <div className="info-item">
                <EmailIcon />
                <Typography>kronpara@gmail.com</Typography>
              </div>
              <Typography variant="body1" className="schedule">
                Program: 8:00 - 16:00
                <br />
                *În funcție de condițiile meteo și programul de funcționare a instalațiilor de urcare
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="contact-form">
              <Typography variant="h6">Formular contact</Typography>
              <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Mesaj"
                  name="message"
                  variant="outlined"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Nume"
                  name="lastName"
                  variant="outlined"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Prenume"
                  name="firstName"
                  variant="outlined"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Email"
                  name="email"
                  variant="outlined"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Telefon"
                  name="phone"
                  variant="outlined"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
                <FormControlLabel
                  control={<Checkbox name="not-robot" checked={isNotRobotChecked} onChange={handleCheckboxChange} />}
                  label="Nu sunt robot"
                />
                <Button variant="contained" color="primary" disabled={!isNotRobotChecked} type="submit">
                  Trimite
                </Button>
              </form>
            </div>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};
