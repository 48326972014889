import { Modal, Box, Typography, IconButton, useTheme } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800, // Set the width of the modal
  bgcolor: "background.paper",
  border: "2px solid ##EEEEEE",
  boxShadow: 24,
  p: 8,
  maxHeight: "80vh",
  overflowY: "auto",
  borderRadius: "16px",
};

interface GeneralTermsProps {
  open: boolean;
  handleClose: () => void;
}

export const GeneralTerms: React.FC<GeneralTermsProps> = ({ open, handleClose }) => {
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="general-terms-title"
        aria-describedby="general-terms-description"
      >
        <Box sx={style}>
          {/* Close Button */}
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="general-terms-title" variant="h6" component="h2" gutterBottom>
            1. DISPOZIȚII GENERALE
          </Typography>
          <Typography id="general-terms-description" variant="body1" component="div">
            <p>
              Vizitarea sau cumpărarea prin orice mijloace (comandă online, telefonic, email, etc) a produselor de pe
              site-ul www.kronpara.ro implică automat acceptarea termenilor și condițiilor de mai jos.
            </p>
            <p>
              Acest site este întreținut și administrat de către KRONPARA, denumită în continuare Furnizor. Persoana
              fizică sau juridică care comandă și achiziționează produse sau servicii prin intermediul site-ului
              www.kronpara.ro, va fi denumită Membru / Client.
            </p>

            <Typography variant="h6" component="h3" gutterBottom>
              2. CONȚINUTUL SITE-ULUI
            </Typography>
            <p>
              KRONPARA definește prin conținut, toate informațiile conținute pe site în forma de text, date, imagini
              (poze). KRONPARA își rezervă dreptul de a actualiza sau de a modifica conținutul acestui site fără a
              anunța în prealabil. Întregul conținut al site-ului www.kronpara.ro este proprietatea Furnizorului și este
              apărat de legile în vigoare pentru protecția drepturilor de autor. Folosirea fără acordul Furnizorului, a
              oricăror elemente din conținut, se pedepsește în conformitate cu legile în vigoare (administrativ, civil
              sau penal).
            </p>

            <Typography variant="h6" component="h3" gutterBottom>
              3. DECLINAREA RESPONSABILITĂȚII
            </Typography>
            <p>
              Fotografiile, descrierea, caracteristicile și accesoriile produselor prezentate pe site sunt doar cu titlu
              informativ, de aceea serviciile livrate pot diferi în orice fel, fără o notificare prealabilă.
            </p>

            <Typography variant="h6" component="h3" gutterBottom>
              4. CONFIDENȚIALITATEA DATELOR PERSONALE
            </Typography>
            <p>
              KRONPARA este operator de date cu caracter personal înregistrat la Autoritatea Națională de Supraveghere a
              Prelucrării Datelor cu Caracter Personal. Conform cerințelor Legii nr. 677/2001 pentru protecția
              persoanelor cu privire la prelucrarea datelor cu caracter personal și libera circulație a acestor date,
              KRONPARA are obligația de a administra în condiții de siguranță și numai pentru scopurile specificate,
              datele personale furnizate despre dumneavoastră, un membru al familiei dumneavoastră ori o altă persoană.
            </p>

            <p>
              Scopul colectării datelor personale este onorarea comenzilor (eliberarea facturii, vouchere),
              reclamă-marketing-publicitate (newsletter) și realizarea de statistici necesare dezvoltării serviciilor
              oferite.
            </p>

            <p>
              Informațiile colectate de către KRONPARA având ca scop onorarea comenzilor, pot fi furnizate parțial
              companiilor de partenere pentru colaborarea in cazul grupurilor organizate.
            </p>

            <p>
              Informațiile colectate de către KRONPARA având ca scop reclamă-marketing-publicitate (newsletter / Social
              Media) nu vor fi furnizate/vândute sau închiriate unor terțe părti.
            </p>

            <p>
              KRONPARA colectează de la clienții săi date cu caracter personal precum nume, prenume, e-mail, număr de
              telefon, adresă, etc. numai dacă acestea sunt furnizate în mod voluntar de către client.
            </p>

            <p>
              Refuzul furnizării a acestor date, duce la imposibilitatea procesării și finalizarii serviciilor si
              inregistrarii ca membru in KRONPARA.
            </p>

            <p>
              Conform Legii nr. 677/2001, clientul are dreptul de acces, intervenție asupra datelor, dreptul de a nu fi
              supus unei decizii individuale și dreptul de a se adresa justiției. Totodată, aveți dreptul sa vă opuneti
              prelucrării datelor personale care vă privesc și să solicitați ștergerea acestora. Pentru exercitarea
              acestor drepturi, vă puteti adresa cu o cerere scrisă, datată și semnată la sediul KRONPARA sau prin
              e-mail la adresa kronpara@gmail.com
            </p>

            <p>
              KRONPARA si www.kronpara.ro nu incurajeaza SPAM-ul, nu furnizeaza datele dvs de contact unor terti
              (persoane fizice sau juridice), nu vinde, nu ofera, nu face schimb de adrese de e-mail obtinute prin
              intermediul acestui site, nu divulga adresa dumneavoastra de e-mail altor persoane care acceseaza paginile
              acestui site, fara acordul dumneavoastra explicit.
            </p>

            <p>
              Orice utilizator care a furnizat explicit pe site-ul www.kronpara.ro adresa sa de email poate opta ca
              aceasta sa fie stearsa din baza de date. Pentru a sterge informatiile furnizate de dvs din baza de date,
              este de ajuns sa ne contactati si sa cereti acest lucru telefonic, prin e-mail.
            </p>

            <p>
              KRONPARA nu isi asuma responsabilitatea pentru pierderile de informatii datorate erorilor sau eventualelor
              probleme de securitate ale serverului ce gazduieste site-ul nostru, sau actiunilor unor terte persoane.
            </p>

            <Typography variant="h6" component="h3" gutterBottom>
              5. GARANȚIE SI COMFORMITATE
            </Typography>
            <p>
              KRONPARA este operator de date cu caracter personal înregistrat la Autoritatea Națională de Supraveghere a
              Prelucrării Datelor cu Caracter Personal. Conform cerințelor Legii nr. 677/2001 pentru protecția
              persoanelor cu privire la prelucrarea datelor cu caracter personal și libera circulație a acestor date,
              KRONPARA are obligația de a administra în condiții de siguranță și numai pentru scopurile specificate,
              datele personale furnizate despre dumneavoastră, un membru al familiei dumneavoastră ori o altă persoană.
            </p>

            <Typography variant="h6" component="h3" gutterBottom>
              6. PREȚURI ȘI TAXE
            </Typography>
            <p>
              Prețul final plătit de către client este format din prețul produsului afișat pe site si in functie de
              promotiile avute + diferenta pana la plata completa.
            </p>

            <p>Plata se poate face în avans, serviciile platite fiind salvate intr-o lista de booking.</p>
            <p>Promoții și oferte speciale</p>
            <p>
              Prețurile produselor de pe acest site sunt informative și pot suferi modificări neanunțate. Promoțiile și
              ofertele speciale sunt valabile în perioada de timp menționată, iar în cazul în care nu se menționează o
              perioadă de timp, acestea sunt valabile în limitele stocurilor disponibile.
            </p>
            <p>Achizitii speciale. Anumite servicii pot fi achizitionate in forma unui cadou / surpriza.</p>

            <Typography variant="h6" component="h3" gutterBottom>
              7. SUPORT TEHNIC ȘI CONSULTANȚĂ PROFESIONALĂ
            </Typography>
            <p>
              Având o experiență de peste 3 ani de zile, oferim clienților suport tehnic și consultanță de specialitate
              în limita resurselor disponibile. Suportul tehnic se oferă prin telefon sau prin email de către
              consultanții noștri de vânzări sau după caz, de către departamentul logistic.
            </p>

            <Typography variant="h6" component="h3" gutterBottom>
              8. LIVRAREA PRODUSELOR
            </Typography>
            <p>Serviciile achiziționate de pe site vor fi livrate doar pe teritoriul Romaniei.</p>

            <Typography variant="h6" component="h3" gutterBottom>
              9. TENTATIVĂ DE FRAUDĂ
            </Typography>
            <p>
              Orice tentativa de acces neautorizat a bazelor de date, datelor personale, serverului sau conținutului
              site-ului www.kronpara.ro va fi raportată autorităților competente și va face obiectul unei plângeri
              penale către acestea.
            </p>

            <Typography variant="h6" component="h3" gutterBottom>
              10. LITIGII
            </Typography>
            <p>
              Orice litigiu apărut între Clienți și Furnizor va fi rezolvat pe cale amiabilă. Pentru consiliere sunati
              la numarul afisat, oricand in timpul programului de lucru de luni pana vineri intre 10:00 si 18:00 –
              Ovidiu Murariu. Gasim solutii pentru solicitarile tale impreuna. Te asiguram de cooperarea noastra si
              speram sa gasim deschidere spre rezolvarea oricarei probleme aparute in serviciul nostru catre tine.
            </p>

            <p>
              În cazul în care nu reușim rezolvarea conflictului pe cale amiabilă, competența revine Instanțelor de
              Judecată Române, de pe raza municipiului Brasov.
            </p>

            <p>
              Prin vizitarea și cumpărarea de produse si servicii prin orice mijloc (comanda online, telefonic, email,
              etc), Clientul confirmă că a citit și acceptat termenii și condițiile de mai sus.
            </p>

            <p>
              Utilizarea site-ului de către Client pentru informare implică totodată acceptarea necondiționată a acestor
              termeni și condiții, inclusiv pe pagina pe care va aflati acum.
            </p>

            <p>
              In nici un caz sectiuni de text sau imagini vor fi folosite in orice mod, fara acordul scris prin email
              oficial de la KRONPARA.
            </p>

            <p>
              În cazul în care nu sunteți de acord cu acești termeni și condiții, vă rugăm să părăsiți acest site si sa
              stergeti manual orice cookie acceptat ulterior.
            </p>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};
