import { Box, Typography, useMediaQuery } from "@mui/material";
import theme from "../../shared/theme/themes";

export const MainText = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box 
      sx={{ 
        padding: "20px", 
        textAlign: "center", 
        width: isMobile ? '100%' : '40%' 
      }}
    >
      <Typography
        variant="h4"
        component="h3"
        sx={{
          fontSize: { xs: "1.2rem", sm: "1.5rem", md: "1.8rem" }, // Responsive font size
          fontWeight: "bold",
          marginBottom: "20px",
        }}
      >
        Descopera Zborul cu Parapanta in Tandem!
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" }, // Responsive font size
        }}
      >
        Localizata in cel mai frumos loc din Romania, KronPara Brașov vă stă la dispoziție cu zboruri în tandem in doua zone principale ale
        Brasovului si nu numai! Alege una dintre zonele tale preferate si haide sa experimentam impreuna frumusetea muntilor.
      </Typography>
      <blockquote>
        “Odată ce ai încercat zborul, vei păși totdeauna pe pământ cu ochii ridicați spre cer, acolo unde ai fost, și vei dori mereu să te
        întorci.” - Leonardo da Vinci
      </blockquote>
    </Box>
  );
};
