import { Modal, Box, IconButton, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800, // Set the width of the modal
  bgcolor: "background.paper",
  border: "2px solid ##EEEEEE",
  boxShadow: 24,
  p: 8,
  maxHeight: "80vh",
  overflowY: "auto",
  borderRadius: "16px",
};

interface GeneralTermsProps {
  open: boolean;
  handleClose: () => void;
}
export const PaymentCondition: React.FC<GeneralTermsProps> = ({ open, handleClose }) => {
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="payment-terms-title"
        aria-describedby="payment-terms-description"
      >
        <Box sx={style}>
          {/* Close Button */}
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="payment-terms-title" variant="h6" component="h2" gutterBottom>
            TERMENII SI CONDITIILE DE PLATA KRONPARA
          </Typography>
          <Typography id="payment-terms-description" variant="body1" component="div">
            <p>
              Vizitarea sau cumpărarea prin orice mijloace (comandă online, telefonic, email, etc) a produselor de pe
              site-ul www.kronpara.ro implică automat acceptarea termenilor și condițiilor de mai jos.
            </p>
            <p>
              Acest site este întreținut și administrat de către KRONPARA, denumită în continuare Furnizor. Persoana
              fizică sau juridică care comandă și achiziționează produse sau servicii prin intermediul site-ului
              www.kronpara.ro, va fi denumită Membru / Client.
            </p>

            <Typography variant="h6" component="h3" gutterBottom>
              Politica de livrare
            </Typography>
            <p>
              După confirmarea platii veti primi pe adresa de email un voucher electronic care va contine un cod unic,
              precum si detaliile legate de tipul de zbor ales si locatia din care se va efectua zborul. Asigura-te ca
              adresa de email este coreta!
            </p>

            <p>
              Nu este necesar ca banii sa fie fizic in contul nostru, veti primi voucherul imediat, pe baza acestei
              confirmari.
            </p>

            <p>
              In cazul platilor online prin card bancar, confirmarea se face automat. Se va emite factura fiscala pe
              numele clientului persoana fizica sau al unei societati comerciale romane.
            </p>

            <Typography variant="h6" component="h3" gutterBottom>
              Politica de retur / anulare
            </Typography>
            <p>
              In termenul de valabilitate al voucher-ului (12 LUNI de la data emiterii) acesta se poate anula si
              returna, iar clientul va primi in cont contravaloarea integrala a acestuia in urmatoarele cazuri:
            </p>
            <ul>
              <li>
                Au fost facute 3 programari, clientul final s-a prezentat de fiecare data la locatia si ora stabilite
                dar de fiecare data zborul a fost anulat din cauza conditiilor meteo nefavorabile sau a
                indisponibilitatii pilotului.
              </li>

              <li>
                Este inca in termenul de 14 zile lucratoare de la achizitionare, prevazut de lege, indiferent de motiv
                si fara nici o justificare.
              </li>
            </ul>

            <p>
              Pentru retur/anularea voucherului va rugam sa ne trimiteti un email pe adresa kronpara@gmail.com cu datele
              voucherului si motivele pentru care doriti restituirea contravalorii voucherului.
            </p>

            <p>
              Dupa expirarea valabilitatii voucherului, acesta nu se mai poate returna. Desi nu se poate anula din alte
              motive, voucherul se poate transfera unei terte persoane, nefiind nominal.
            </p>

            <p>
              OBS: In cazul in care perioada de valabilitate a voucherului a expirat si din diferite motive care nu tin
              de conditiile meteorologice nevaforabile si sau o rezervare in prealabil a zborului in termenul de
              valabilitate al voucherului, atunci se va percepe o taxa suplimentara de 150 de lei, cost de reprelungire
              valabilitate voucher, valabil pentru inca o luna de zile.
            </p>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};
