import { Box, Container, IconButton, ImageList, ImageListItem } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import car1 from "../../shared/images/carousel/car-1.jpg";
import car2 from "../../shared/images/carousel/car-2.jpg";
import car3 from "../../shared/images/carousel/cra-3.jpg";
import "./MediaCarousel.component.scss";

const images = [car1, car2, car3];

const MediaCarousel = () => {
  return (
    // <Container maxWidth="xl" sx={{paddingLeft: 0, paddingRight:0}}>
      <Box sx={{width:'100vw', margin: 0, p:0, left:0}}>
      <div className="media-gallery" id="gallery">
        <h2 className="header-title">
          <CameraAltIcon className="icon" /> Acum și tu poți face parte din peisajul nostru
        </h2>
        <div className="gallery-container">
          <IconButton className="arrow prev">
            <ArrowBackIosIcon />
          </IconButton>
          <ImageList className="image-list" cols={3} gap={20}>
            {images.map((image, index) => (
              <ImageListItem key={index}>
                <img
                  src={`${image}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt={`Media ${index + 1}`}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
          <IconButton className="arrow next">
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
      </div>
      </Box>
    // </Container>
  );
};
export default MediaCarousel;
