// eslint-disable-next-line @typescript-eslint/no-unused-vars
import headerImg from "../../shared/images/bg.jpg";
import logo from "../../shared/images/LogoColor.png";
import Button from "@mui/material/Button";

export const Header = () => {
  return (
    <div className="home" id="home">
      <div className="header-container">
        <img src={headerImg} alt="Header" className="header-image" />
        <div className="overlay">
          <h1>
            Descoperă cum este
            <br /> să trăiești printre nori!
          </h1>
          <p>Exclusiv și în siguranță</p>
          <Button variant="contained" className="cta-button">
            Rezervă un zbor
          </Button>
        </div>
      </div>
    </div>
  );
};
