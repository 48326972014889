import { configureStore } from '@reduxjs/toolkit';
import shoppingCartReducer from './features/shoppingCart/shoppingCartSlice';
import productsCollectionSlice from './reducers/products/productsCollectionSlice';
import { loadCartState, saveCartState } from '../utils/localStorageUtils';
import paymentSlice from './features/payment/paymentSlice';
import ordersSlice from './features/orders/ordersSlice';

const preloadedState = {
  shoppingCart: loadCartState()
}
export const store = configureStore({
  reducer: {
    payment: paymentSlice,
    productsCollection: productsCollectionSlice,
    shoppingCart: shoppingCartReducer,
    orders: ordersSlice
  },
  preloadedState,
});

store.subscribe(()=> {
  saveCartState(store.getState().shoppingCart)
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch;