import React, { Dispatch, useEffect, useState } from "react";
import { Box, Button, CardMedia, CircularProgress, Typography, useMediaQuery } from "@mui/material";
import "./OfferCards.component.scss";
import bunlocInitiere from "../../shared/images/bunloc-initiere.webp";
import bonlocAvansat from "../../shared/images/bunloc-avansat.webp";
import postavaruInitiere from "../../shared/images/postavaru-initiere.webp";
import postavaruAvansat from "../../shared/images/postavaru-avansat.jpeg";
import { AccessTime, Camera, Info, Landscape } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../store/features/shoppingCart/shoppingCartSlice";
import { RootState, AppDispatch } from "../../store/store";
import { IProducts } from "../../models/IProducts";
import { getProducts } from "../../store/actions/getProducts.actions";
import theme from "../../shared/theme/themes";

const OfferCards: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const products = useSelector((state: RootState) => state.productsCollection.products);
  const loading = useSelector((state: RootState) => state.productsCollection.loading);

  // Filter products by "main" page tag
  const filteredProducts = products.filter((product) => product.pageTags?.includes("main"));

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  const images = [bunlocInitiere, bonlocAvansat, postavaruInitiere, postavaruAvansat];

  const getRandomImage = () => {
    return images[Math.floor(Math.random() * images.length)];
  };

  const handleAddToCart = (product: IProducts) => {
    dispatch(addToCart(product));
  };
  return loading === "pending" ? (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      {" "}
      <CircularProgress />
    </Box>
  ) : // Mobile version
  isMobile ? (
    <Box className="offer-cards-mobile-container">
      <Typography variant="h6" className="title-mobile">
        Alege Una Dintre Ofertele Noastre
      </Typography>
      {/* Cards products */}
      <Box className="cards-mobile" id="offers">
        {filteredProducts.map((product) => (
          <Box key={product._id} className="card-mobile" style={{ backgroundImage: `url(${getRandomImage()})` }}>
            <Box className="card-content-mobile">
              <Typography variant="h5" className="card-title">
                {product.name}
              </Typography>
              <Typography variant="h6" className="card-price">
                {product.price} LEI
              </Typography>
              <Button
                variant="contained"
                className="add-to-cart-button-mobile"
                onClick={() => handleAddToCart(product)}
              >
                Adaugă în coș
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  ) : (
    // Desktop version
    <Box className="offer-cards-container">
      <Typography variant="h6" className="title">
        Alege Una Dintre Ofertele Noastre
      </Typography>
      <Box className="cards" id="offers">
        {filteredProducts.map((product) => (
          <Box key={product._id} className="card">
            <Box className="card-inner">
              <Box className="card-front">
                <Typography variant="h5" className="card-title">
                  {product.name}
                </Typography>
                <Typography variant="h6" className="card-price">
                  {product.price} LEI
                </Typography>
              </Box>
              <Box className="card-back">
                <CardMedia component="img" image={getRandomImage()} alt={product.name} />
                <Box className="card-description">
                  <Typography variant="subtitle2" className="card-back-title">
                    Perioada de zbor: <br />
                    <strong>{product.season}</strong>
                  </Typography>
                  {product.description.map((desc, index) => (
                    <Typography key={index} variant="subtitle2" className="card-back-description">
                      <span className={`icon ${desc.icon}`} /> {desc.text}
                    </Typography>
                  ))}
                  <div className="left-text">
                    <Typography variant="body2" className="card-subtitle">
                      Durata zborului {product.duration}
                    </Typography>
                  </div>
                  <Button variant="contained" className="add-to-cart-button" onClick={() => handleAddToCart(product)}>
                    Adaugă în coș
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default OfferCards;
