import axios from "axios";
import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { IMessages } from "../../models/IMessages";

const isDevelopment = process.env.NODE_ENV === "development";

export const newMessage = createAsyncThunk<IMessages, IMessages>(
  "messages/createMessage",
  async (formData: IMessages, { rejectWithValue }) => {
    try {
      const response = await axios.post<IMessages>(
        isDevelopment
          ? `${process.env.REACT_APP_BACKEND_DEVELOPMENT_URL}/messages/`
          : `${process.env.REACT_APP_BACKEND_URL}/messages/`,
        formData
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
