import "./App.scss";
import Home from "./components/home/Home.component";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./shared/theme/themes";
import { Provider } from "react-redux";
import { store } from "./store/store";
import {  Route, Routes, BrowserRouter } from "react-router-dom";
import { CompleteOrder } from "./components/complete-order/CompleteOrder.components";
import { Products } from "./components/products/Products.component";

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/voucher" element={<Products />} />
              <Route path="/complete-order" element={<CompleteOrder />} />
            </Routes>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
