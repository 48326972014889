import { Box, IconButton, Paper, Typography, TextField, Button } from '@mui/material'
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import './Chat.component.scss';

export const Chat = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleChat = () => {
      setIsOpen(!isOpen);
    };
  
  return (
    <Box className={`chat-container ${isOpen ? 'open' : ''}`}>
    <IconButton onClick={toggleChat} className="chat-toggle">
      {isOpen ? <CloseIcon /> : <ChatIcon />}
    </IconButton>
    {isOpen && (
      <Paper className="chat-box">
        <Typography variant="h6" className="chat-title">Contacteaza-ne pe Chat</Typography>
        <Box className="chat-messages">
          {/* Chat messages will go here */}
        </Box>
        <Box className="chat-input">
          <TextField fullWidth variant="outlined" placeholder="Scrie un mesaj..." />
          <Button variant="contained" color="primary">Trimite</Button>
        </Box>
      </Paper>
    )}
  </Box>
  )
}
