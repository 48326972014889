export const loadCartState = () => {
    try {
        const serializeState = localStorage.getItem('cart');
        if(serializeState === null){
            return undefined;
        }
        return JSON.parse(serializeState);
    } catch (error: any) {
        return error.message
    }
}

export const saveCartState = (state: any) => {
    try {
        const serializeState = JSON.stringify(state);
        localStorage.setItem('cart', serializeState)
    } catch (error: any) {
        return error.message
        
    }
}