import InfoIcon from '@mui/icons-material/Info';
import './AboutFly.component.scss';
import { Container, useMediaQuery } from '@mui/material';
import theme from '../../shared/theme/themes';

export const AboutFly = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Container>
    <div className={`about-flight ${isMobile ? 'center-text' : ''}`} id="about">
      <h2><InfoIcon className="icon" /> Despre zbor.</h2>
      <p>Zborul cu parapanta în tandem constă în decolare, zbor și aterizare.</p>
      <div className="text-container">
        <ul>
          <li><strong>Decolarea:</strong> se face în câțiva pași de alergare în care parapanta se ridică deasupra capului, indicațiile sunt primite direct de la pilot.</li>
          <li><strong>Zborul:</strong> planarea în aer în care se poate savura zborul, peisajul și se poate degusta adrenalina. Dacă condiția meteo este prielnică și înălțimea câștigată ne permite să angajăm parapanta în manevre spectaculoase, vă vom oferi adrenalina și ocazia să simțiți forțele G.</li>
          <li><strong>Aterizarea:</strong> se face în siguranță, unde este necesar să facem câțiva pași în alergare.</li>
        </ul>
      </div>
    </div>
  </Container>
  )
}