import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IProducts } from "../../../models/IProducts";
import { getProducts } from "../../actions/getProducts.actions";

interface ProductState {
  products: IProducts[];
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: string | null;
}

const initialState: ProductState = {
  products: [],
  loading: "idle" as "idle" | "pending" | "succeeded" | "failed",
  error: null,
};

export const productsCollectionSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProducts: (state, action: PayloadAction<IProducts[]>) => {
        state.products = action.payload;
        state.loading = 'succeeded';
      },
      setProductsLoading: (state) => {
        state.loading = 'pending';
      },
      setProductsError: (state, action: PayloadAction<string>) => {
        state.error = action.payload;
        state.loading = 'failed';
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(getProducts.fulfilled, (state, action: PayloadAction<IProducts[]>) => {
        state.products = action.payload;
        state.loading = 'succeeded';
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message || 'Failed to fetch products';
      })
  },
});

export const { setProducts, setProductsLoading, setProductsError } = productsCollectionSlice.actions;
export default productsCollectionSlice.reducer;