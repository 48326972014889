import { Snackbar, Alert, Button, Box, Collapse } from "@mui/material";
import { useState } from "react";

export const CookiesConsent = () => {
  const [open, setOpen] = useState(true);
  const [expanded, setExpanded] = useState(false);

  const handleAccept = () => {
    document.cookie = "cookieConsent=true; max-age=31536000; path=/";
    setOpen(false);
  };

  const handleToggleExpand = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <Snackbar open={open} onClose={handleAccept} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
      <Alert
        onClose={handleAccept}
        severity="info"
        action={
          <Button color="inherit" size="small" onClick={handleAccept}>
            Accept
          </Button>
        }
        sx={{ maxWidth: "400px", textAlign: "left", padding: "40px" }}
      >
        <Box sx={{ paddingBottom: "16px" }}>
          Prin accesarea site-ului agreați integral Termenii și Condițiile de utilizare a site-ului și a informațiilor
          din acesta, înțelegeți și acceptați că site-ul folosește tehnologia cookies pentru a asigura diferite
          funcționalități necesare funcționarii în integralitate a site-ului.
        </Box>

        <Collapse in={expanded}>
          <Box sx={{ paddingBottom: "16px" }}>
            Prin transmiterea formularelor din site, a emailurilor sau a oricăror mijloace de comunicare cu noi vă dați
            acordul de prelucrare a datelor dumneavoastră personale conform regulamentului GDPR. Toate datele personale
            pe care le operăm prin site sunt stocate și prelucrate în UE respectând recomandările de securitate. Aveți
            dreptul de a solicita ștergerea datelor conform legii.
            <br />
            <br />
            Dacă nu sunteți de acord cu oricare dintre aceste condiții atunci nu aveți dreptul de a folosi site-ul.
            <br />
            <br />
            Datele pe care le colectăm. Conform regulametului EU 679/2016 și cu acceptul dumneavoastră societatea
            noastră va colecta și prelucra următoarele date personale: nume, telefon, adresa de email și adresa poștală
            în următoarele scopuri:
            <ul>
              <li>Rezervarea unui zbor cu parapanta</li>
              <li>Pentru a vă răspunde în scris eventualelor întrebări</li>
            </ul>
            Stocarea datelor. Datele astfel colectate vor fi stocate în baza noastră de date pentru o perioadă de 360
            zile. În această perioadă datele dumneavoastră nu vor fi folosite în alte scopuri și nu vor fi transferate
            unei terțe entități. Ne puteți contacta pe adresa: kronstadtparagliding@gmail.com pentru a verifica starea
            datelor dumneavoastră și a solicita ștergerea sau modificarea acestora.
            <br />
            <br />
            Comunicarea informațiilor este protejată prin protocolul HTTPS. Datele sunt stocate pe servere securizate,
            aflate în locații sigure; periodic se fac copii de siguranță. Ștergem informațiile cu caracter personal după
            360 zile de la ultima interacțiune, dacă nu ați solicitat păstrarea informațiilor pentru a primi informații
            despre celelalte servicii oferite, promoții sau oferte.
          </Box>
        </Collapse>

        <Button color="inherit" size="small" onClick={handleToggleExpand}>
          {expanded ? "Show Less" : "Read More"}
        </Button>
      </Alert>
    </Snackbar>
  );
};
