import { useDispatch, useSelector } from "react-redux";
import { Footer } from "../footer/Footer.component";
import { Hero } from "../hero/Hero.component";
import { Menu } from "../menu/Menu.component";
import "./Products.component.scss";
import { AppDispatch, RootState } from "../../store/store";
import { useEffect } from "react";
import { getProducts } from "../../store/actions/getProducts.actions";
import { Typography, Grid, Card, CardMedia, CardContent, CardActions, Button, Box } from "@mui/material";
import bunlocInitiere from "../../shared/images/bunloc-initiere.webp";
import bonlocAvansat from "../../shared/images/bunloc-avansat.webp";
import postavaruInitiere from "../../shared/images/postavaru-initiere.webp";
import postavaruAvansat from "../../shared/images/postavaru-avansat.jpeg";
import { IProducts } from "../../models/IProducts";
import { addToCart } from "../../store/features/shoppingCart/shoppingCartSlice";
import LandscapeIcon from "@mui/icons-material/Landscape";
import SportsKabaddiIcon from "@mui/icons-material/SportsKabaddi";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import VerifiedIcon from "@mui/icons-material/Verified";

export const Products = () => {
  const dispatch = useDispatch<AppDispatch>();
  const products = useSelector((state: RootState) => state.productsCollection.products);

  const loading = useSelector((state: RootState) => state.productsCollection.loading);

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  const images = [bunlocInitiere, bonlocAvansat, postavaruInitiere, postavaruAvansat];

  const getRandomImage = () => {
    return images[Math.floor(Math.random() * images.length)];
  };

  const handleAddToCart = (product: IProducts) => {
    dispatch(addToCart(product));
  };
  return (
    <>
      <Menu />
      <Hero />
      <Box sx={{ flexGrow: 1, p: { xs: "2rem", lg: "4rem" } }}>
        {loading === "pending" ? (
          <Typography variant="h6">Se încarcă produsele...</Typography>
        ) : (
          <Grid container spacing={4}>
            <Grid item lg={12} sm={12} sx={{ marginBottom: "4rem" }}>
              <Typography variant="h4" sx={{ textAlign: "center", marginBottom: "2rem" }}>
                Alege una dintre experientele noastre
              </Typography>
              <Grid container lg={12} sm={12} justifyContent="center" spacing={6}>
                {/* Icon 1: Peisaje */}
                <Grid item>
                  <Grid container direction="column" alignItems="center">
                    <LandscapeIcon sx={{ fontSize: "5rem" }} />
                    <Typography variant="h6">Natură</Typography>
                  </Grid>
                </Grid>
                {/* Icon 2: Experiență */}
                <Grid item>
                  <Grid container direction="column" alignItems="center">
                    <LocalActivityIcon sx={{ fontSize: "5rem" }} />
                    <Typography variant="h6">Experiență</Typography>
                  </Grid>
                </Grid>
                {/* Icon 3: Adrenalină */}
                <Grid item>
                  <Grid container direction="column" alignItems="center">
                    <SportsKabaddiIcon sx={{ fontSize: "5rem" }} />
                    <Typography variant="h6">Adrenalină</Typography>
                  </Grid>
                </Grid>
                {/* Icon 4: Distracție */}
                <Grid item>
                  <Grid container direction="column" alignItems="center">
                    <EmojiPeopleIcon sx={{ fontSize: "5rem" }} />
                    <Typography variant="h6">Distracție</Typography>
                  </Grid>
                </Grid>
                {/* Icon 5: Pilot Certificat */}
                <Grid item>
                  <Grid container direction="column" alignItems="center">
                    <VerifiedIcon sx={{ fontSize: "5rem" }} />
                    <Typography variant="h6">Pilot Certificat</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {products.map((product) => (
              <Grid item xs={12} sm={12} md={3} key={product._id}>
                <Card>
                  <CardMedia component="img" height="300" image={getRandomImage()} alt={product.name} />
                  <CardContent>
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{
                        textAlign: "center",
                        fontFamily: "Inter, sans-serif",
                        fontWeight: 700,
                      }}
                    >
                      {product.name}
                    </Typography>
                    <Typography variant="h6" color="primary" sx={{ mt: 4 }}>
                      {product.price} LEI
                    </Typography>
                    {product.description.map((desc, index) => (
                      <Typography key={index} variant="subtitle2" className="card-back-description" color="#31363F">
                        <span className={`icon ${desc.icon}`} /> {desc.text}
                      </Typography>
                    ))}
                  </CardContent>
                  <CardActions>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "1rem",
                      }}
                    >
                      <Button
                        size="large"
                        color="primary"
                        variant="contained"
                        onClick={() => handleAddToCart(product)}
                        sx={{ color: "white", marginBottom: "1rem" }}
                        startIcon={<ShoppingCartIcon />}
                      >
                        Adaugă în coș
                      </Button>
                    </Box>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
      <Footer />
    </>
  );
};

