import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
import React from 'react'

interface InfoClientDialogProps {
    open: boolean;
    handleClose: () => void;
    }
export const InfoClientDialog  = ({open, handleClose}: InfoClientDialogProps) => {
  return (
    <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Informații despre programări</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Zborul cu parapanta depinde foarte mult de condițiile meteorologice, pentru a efectua zborul avem nevoie de vreme bună. După achiziționarea voucher-ului te vom contacta telefonic pentru a stabili împreună data la care vom zbura.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Închide
          </Button>
        </DialogActions>
      </Dialog>
  )
}
